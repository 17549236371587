<div class="input-group-lg">
  <form (ngSubmit)="submitUser()" [formGroup]="form">
    <div class="form-group">
      <label class="control-label" for="name">Navn*</label>
      <input
        class="form-control input-lg"
        formControlName="name"
        id="name"
        name="name"
        placeholder="Navn"
        required
        type="text"
      />
    </div>
    <div class="form-group">
      <label class="control-label" for="email">E-post*</label>
      <input
        class="form-control input-lg"
        formControlName="email"
        id="email"
        name="email"
        placeholder="E-post"
        required
        type="text"
      />
    </div>
    @if (!user()) {
      <div class="form-group">
        <input
          class="form-check-input"
          formControlName="checkbox"
          id="acceptedTerms"
          name="acceptedTerms"
          required
          type="checkbox"
        />
        <label class="form-check-label ml-3 d-inline-block ml-3" for="acceptedTerms"
          >Jeg godkjenner*
          <a class="text-green" href="https://www.nibio.no/tjenester/gardskart/brukervilkar-gardskart" target="_blank"
            >brukervilkår</a
          ></label
        >
      </div>
    }
    <div class="form-group">
      <button [disabled]="!form.valid" class="btn btn-gk-primary btn-xlarge" type="submit">
        {{ submitButtonText }}
      </button>
    </div>
  </form>
</div>
