import { Component, EventEmitter, inject, input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormsModule,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { UserData } from '@models/dto/UserData';

@Component({
  imports: [FormsModule, ReactiveFormsModule],
  selector: 'gk-registration-form',
  styleUrls: ['./registration-form.component.scss'],
  templateUrl: './registration-form.component.html',
})
export class RegistrationFormComponent implements OnInit {
  private readonly fb = inject(NonNullableFormBuilder);

  readonly user = input<UserData>();
  @Output() registerUser = new EventEmitter<UserData>();

  acceptedTerms = false;
  form!: UntypedFormGroup;
  registrationUser: UserData;
  submitButtonText = 'Opprett bruker';

  constructor() {
    this.registrationUser = new UserData();
  }

  emailValidator(): ValidatorFn {
    return (control: AbstractControl): null | Record<string, any> => {
      const emailValidation =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const validationResult = emailValidation.test(control.value);

      if (!validationResult) {
        return { emailValidationFailure: 'Ugyldig email addresse.' };
      }

      return null;
    };
  }

  isFormDirty() {
    return this.form.dirty;
  }

  ngOnInit(): void {
    const user = this.user();
    if (user) {
      this.registrationUser = new UserData();
      this.registrationUser.id = user.id;
      this.registrationUser.name = user.name;
      this.registrationUser.email = user.email;
      this.submitButtonText = 'Lagre';
      this.acceptedTerms = true;
    }

    this.form = this.fb.group<RegistrationFormFieldsType>({
      checkbox: this.fb.control<boolean>(this.acceptedTerms, [Validators.required]),
      email: this.fb.control<string>(this.registrationUser.email, [Validators.required, this.emailValidator()]),
      name: this.fb.control<string>(this.registrationUser.name, [Validators.required]),
    });
  }

  submitUser() {
    this.registrationUser.name = this.form.value.name.trim();
    this.registrationUser.email = this.form.value.email;

    this.registerUser.emit(this.registrationUser);
  }
}

type RegistrationFormFieldsType = {
  checkbox: FormControl<boolean>;
  email: FormControl<string>;
  name: FormControl<string>;
};
